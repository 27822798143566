import { AGORA_RTC_CONNECTION_STATE_CONNECTING } from "constants/agora";
import * as actions from "../actions/actionTypes";

const initialState = {
   layout: {
      streamerBreakoutRoomModalOpen: false,
      viewerBreakoutRoomModalOpen: false,
      viewerCtaModalOpen: false,
      leftMenuOpen: true,
      focusModeEnabled: false,
   },
   stats: {
      numberOfViewers: 0,
   },
   streaming: {
      isPublished: false,
      videoIsPaused: false,
      videoIsMuted: false,
      playAllRemoteVideos: false,
      muteAllRemoteVideos: false,
      playLocalVideo: true,
      playLocalAudio: true,
      unmuteFailedMutedRemoteVideos: false,
      unpauseFailedPlayRemoteVideos: false,
      spyModeEnabled: false,
   },
   agoraState: {
      rtcConnectionState: {
         // curState: undefined,
         curState: AGORA_RTC_CONNECTION_STATE_CONNECTING,
         prevState: undefined,
         reason: undefined,
         warning: undefined,
      },
      rtcError: {
         code: undefined,
         message: undefined,
         name: undefined,
         data: undefined,
      },
      deviceErrors: {
         cameraDenied: false,
         microphoneDenied: false,
         cameraIsUsedByOtherApp: false,
         microphoneIsUsedByOtherApp: false,
      },
      screenSharePermissionDenied: false,
      sessionIsUsingCloudProxy: false,
      primaryClientJoined: false,
   },
};

const streamReducer = (state = initialState, { type, payload }) => {
   switch (type) {
      case actions.SET_STREAMER_PUBLISHED:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               isPublished: payload,
            },
         };
      case actions.SET_SPY_MODE:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               spyModeEnabled: payload,
            },
         };
      case actions.SET_FOCUS_MODE:
         return {
            ...state,
            layout: {
               ...state.layout,
               focusModeEnabled: payload,
            },
         };
      case actions.SET_AGORA_RTC_CONNECTION_STATE:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               rtcConnectionState: payload,
            },
         };
      case actions.SET_AGORA_RTC_ERROR:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               rtcError: payload,
            },
         };
      case actions.SET_SESSION_IS_USING_CLOUD_PROXY:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               sessionIsUsingCloudProxy: payload,
            },
         };
      case actions.CLEAR_AGORA_RTC_ERROR:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               rtcError: initialState.agoraState.rtcError,
            },
         };
      case actions.TOGGLE_LEFT_MENU:
         return {
            ...state,
            layout: {
               ...state.layout,
               leftMenuOpen: !state.layout.leftMenuOpen,
            },
         };
      case actions.OPEN_LEFT_MENU:
         return {
            ...state,
            layout: {
               ...state.layout,
               leftMenuOpen: true,
            },
         };
      case actions.CLOSE_LEFT_MENU:
         return {
            ...state,
            layout: {
               ...state.layout,
               leftMenuOpen: false,
            },
         };
      case actions.SET_DEVICE_ERROR:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               deviceErrors: {
                  ...state.agoraState.deviceErrors,
                  ...payload,
               },
            },
         };
      case actions.SET_SCREEN_SHARE_DENIED_ERROR:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               screenSharePermissionDenied: payload,
            },
         };
      case actions.SET_AGORA_PRIMARY_CLIENT_JOINED:
         return {
            ...state,
            agoraState: {
               ...state.agoraState,
               primaryClientJoined: payload,
            },
         };
      case actions.TOGGLE_LOCAL_VIDEO:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               playLocalVideo: !state.streaming.playLocalVideo,
            },
         };
      case actions.TOGGLE_LOCAL_AUDIO:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               playLocalAudio: !state.streaming.playLocalAudio,
            },
         };
      case actions.MUTE_ALL_REMOTE_VIDEOS:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               muteAllRemoteVideos: true,
            },
         };
      case actions.UNMUTE_MUTED_REMOTE_VIDEOS_ON_FAIL:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               unmuteFailedMutedRemoteVideos: true,
               videoIsMuted: false,
            },
         };
      case actions.UNPAUSE_PAUSED_REMOTE_VIDEOS_ON_FAIL:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               unpauseFailedPlayRemoteVideos: true,
               videoIsPaused: false,
            },
         };
      case actions.UNMUTE_ALL_REMOTE_VIDEOS:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               videoIsMuted: false,
               muteAllRemoteVideos: false,
            },
         };
      case actions.SET_VIDEO_IS_MUTED:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               videoIsMuted: true,
               unmuteFailedMutedRemoteVideos: false,
            },
         };
      case actions.SET_VIDEO_IS_PAUSED:
         return {
            ...state,
            streaming: {
               ...state.streaming,
               videoIsPaused: true,
               unpauseFailedPlayRemoteVideos: false,
            },
         };
      case actions.OPEN_STREAMER_BREAKOUT_MODAL:
         return {
            ...state,
            layout: { ...state.layout, streamerBreakoutRoomModalOpen: true },
         };
      case actions.CLOSE_STREAMER_BREAKOUT_MODAL:
         return {
            ...state,
            layout: { ...state.layout, streamerBreakoutRoomModalOpen: false },
         };
      case actions.OPEN_VIEWER_BREAKOUT_MODAL:
         return {
            ...state,
            layout: { ...state.layout, viewerBreakoutRoomModalOpen: true },
         };
      case actions.CLOSE_VIEWER_BREAKOUT_MODAL:
         return {
            ...state,
            layout: { ...state.layout, viewerBreakoutRoomModalOpen: false },
         };
      case actions.OPEN_VIEWER_CTA_MODAL:
         return {
            ...state,
            layout: { ...state.layout, viewerCtaModalOpen: true },
         };
      case actions.CLOSE_VIEWER_CTA_MODAL:
         return {
            ...state,
            layout: { ...state.layout, viewerCtaModalOpen: false },
         };
      case actions.SET_NUMBER_OF_VIEWERS:
         return {
            ...state,
            stats: { ...state.stats, numberOfViewers: payload },
         };
      default:
         return state;
   }
};

export default streamReducer;
