export const searchImage =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/ui-icons%2Fsearch-icon.svg?alt=media&token=bc443ee3-a0c6-4a5f-bfc9-6a2c038f9f67";
export const companyLogoPlaceholder =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/mentors-pictures%2Fplaceholder.png?alt=media";
export const signInImage =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/ui-icons%2Fsign-in-icon.svg?alt=media&token=3b3b804b-84c2-4dd6-9fc5-8a580abdd4b4";
export const analyticsSVG =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2FanalyticsIllustration.svg?alt=media&token=bf5d2bd5-ac8d-4bf4-b787-8a116f7cf755";
export const analyticsPreviewImage =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fanalytics-preview.webp?alt=media&token=545502d8-c0f8-4e27-92e1-640dc2bacc61";
export const streamerImage =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fstreamer.webp?alt=media&token=20bd52bc-860c-4f62-8292-9800e6691cee";
export const rectangle1 =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Frectangle-1.svg?alt=media&token=da6df4f2-a578-463b-9441-70acf6e0dcf5";
export const rectangle2 =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Frectangle-2.svg?alt=media&token=da6df4f2-a578-463b-9441-70acf6e0dcf5";
export const reachShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Freach-shape.svg?alt=media&token=9b9cf2fd-c52c-4ed0-845c-06dcd559ba1d";
export const measureShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fmeasure-shape.svg?alt=media&token=96bcc345-e84f-4d4e-9908-97d60296d08c";
export const engageShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fengage-shape.svg?alt=media&token=447df635-831d-49ad-ab15-e929193ce3a2";
export const calendarIcon =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Ficons%2Fcalendar.svg?alt=media&token=526b659f-4bb9-436e-ac4a-d870ffab081b";
export const playIcon =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Ficons%2Fplay.svg?alt=media&token=00ce21f1-eff3-4102-b0c3-a360374e7515";
export const laptopDemo =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Flaptop-demo.webp?alt=media&token=c1793c9e-00e4-4033-87a7-262f6c72f1fd";
export const mainBackground =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fmain-background.webp?alt=media&token=1da0330b-8d48-4cc2-8cd4-ced561a0a931";
export const alternateBackground =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Falternate_background.webp?alt=media&token=8b374f95-ce1f-42d6-8b79-8d502ed218ac";
export const alternateStudentBackground =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fstudent-main-background.webp?alt=media&token=f6597bc5-71df-4445-95c8-38134ad7be79";
export const landingBottomBackground =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fbottom-background.webp?alt=media&token=05cc7acd-b876-4944-b828-6a67ba501d1c";
export const blankLaptop =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Flaptop.webp?alt=media&token=41342a75-c0a3-4462-93b8-6fc1a7ff2558";
export const blankUIQAndA =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fscreen-blank.webp?alt=media&token=d3a37a1c-af2f-46b4-a107-6f6077f3f788";
export const laptopUi =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Flaptop-ui.webp?alt=media&token=15f27c65-a8d4-409f-bc19-a1b73c58fc25";
export const mouse =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fmouse.svg?alt=media&token=8078e3f1-e366-48d2-aefd-749c7227e629";
export const star =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fstar.svg?alt=media&token=38c197b5-f87e-4ef1-bd65-ad19e25bf445";
export const trackAnalytics =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Ftrackanalytics.svg?alt=media&token=dc625a10-55e7-4ba7-86a0-bd18e488e464";
export const phone =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fphone.svg?alt=media&token=2113034e-1219-4384-ac1a-b9a7ce9748a6";
export const promote =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fpromote.svg?alt=media&token=2ec15325-fcb8-4d9c-a221-634a39075c61";
export const ipad =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fipad.jpg?alt=media&token=4e157eb7-a10b-4aed-9324-00922f96e33b";
export const livestream =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Flivestream.svg?alt=media&token=e7da7142-ce12-48ba-8937-b1e2be132720";
export const wallet =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fwallet.svg?alt=media&token=f05c1fa4-b193-46a3-8888-95a5c0b5e016";

export const company_building =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/placeholders%2Fcompany-banner.webp?alt=media&token=d50893e1-11cb-43b3-845f-f66a22c55f73";
export const careerfairyLogo = "/logo_teal.svg";

export const basicEmailTemplate =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/email-template-images%2Fbasic-email-template.png?alt=media&token=4ede176e-c84a-4a91-bceb-b80648bb9773";

export const personShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fperson-shape.png?alt=media&token=5a64716f-4b58-4bf1-9232-724316650ecd";

export const locationShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Flocation-shape.png?alt=media&token=e2ec3c7c-e697-4c62-8589-b1d78fb63190";

export const suitCaseShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Fsuitcase-shape.png?alt=media&token=fa4b98a6-3270-4bc6-b8fb-3c460b5a5242";
export const lightBulbShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/landing%20photos%2Flight-bulb-shape.png?alt=media&token=1270e061-aac5-4db2-832a-b7413ae13e2b";

export const ideaShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/ui-icons%2Fidea-shape.png?alt=media&token=910ea4f6-818b-4400-86ca-eb26e0cca201";

export const qualityShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/ui-icons%2Fquality-shape.png?alt=media&token=7dcfaa45-8461-46df-b122-00a34e9ba55d";

export const honestyShape =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/ui-icons%2Fhonesty-shape.png?alt=media&token=4d0d5c55-91bb-4f28-ad66-6397cf65f242";

export const placeholderBanner =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/group-banners%2Fdefault-banner.svg?alt=media&token=9c53d78f-8f4d-420a-b5ef-36a8fd1c1ee0";
export const chromeLogo =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/random-logos%2Fchrome.svg?alt=media&token=516e705a-bafa-4a43-99f7-e184cc85b557";
export const edgeLogo =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/random-logos%2Fedge.svg?alt=media&token=f4165230-170e-454f-9fca-adea53798b9f";
export const safariLogo =
   "https://firebasestorage.googleapis.com/v0/b/careerfairy-e1fd9.appspot.com/o/random-logos%2Fsafari-logo.svg?alt=media&token=d939e1f5-424e-44be-91d1-78b0e171f1de";
