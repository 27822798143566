import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import SessionStorageUtil from "../../util/SessionStorageUtil";

const config = {
   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

/**
 * Create a custom firebase instance
 *
 * @param name Instance name
 * @param firestoreSettings
 */
export const createFirebaseInstance = (
   name: string,
   firestoreSettings?: firebase.firestore.Settings
): firebase.app.App => {
   const existingApp = firebase.apps.find((app) => app.name === name);
   if (existingApp) {
      return existingApp;
   }

   const app = firebase.initializeApp(config, name);

   app.firestore().settings(getFirestoreSettings(firestoreSettings));

   if (
      process.env.NODE_ENV === "development" &&
      process.env.NEXT_PUBLIC_FIREBASE_EMULATORS
   ) {
      app.auth().useEmulator("http://localhost:9099");
      app.firestore().useEmulator("localhost", 8080);
      app.functions().useEmulator("localhost", 5001);
      console.log("You're connected to the emulators!");
   }

   return app;
};

const getFirestoreSettings = (
   firestoreSettings?: firebase.firestore.Settings
) => {
   const firestoreDefaultSettings = { merge: true };

   // The user doesn't seem to have Firestore connectivity, let's enable the long polling mode
   // This mode is set on FirebaseUtils.js
   if (typeof window !== "undefined") {
      if (SessionStorageUtil.getIsLongPollingMode()) {
         firestoreDefaultSettings["experimentalForceLongPolling"] = true;
         console.warn(
            "Firestore settings with experimentalForceLongPolling=true"
         );
      }
   }

   return Object.assign(firestoreDefaultSettings, firestoreSettings);
};

// Default instance that we can re-use, default settings for firestore, auth, etc
// [DEFAULT] app name is required for redux-firestore 🤦
const firebaseApp: firebase.app.App = createFirebaseInstance("[DEFAULT]");

export default firebaseApp;
