export const AUTH_START = "AUTH_START";
export const AUTH_END = "AUTH_END";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const CLEAN_UP = "CLEAN_UP";

export const VERIFY_START = "VERIFY_START";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAIL = "VERIFY_FAIL";

export const RECOVERY_START = "RECOVERY_START";
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS";
export const RECOVERY_FAIL = "RECOVERY_FAIL";

export const PROFILE_EDIT_START = "PROFILE_EDIT_START";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAIL = "PROFILE_EDIT_FAIL";

export const DELETE_START = "DELETE_START";
export const DELETE_FAIL = "DELETE_FAIL";

export const ADD_TODO_START = "ADD_TODO_START";
export const ADD_TODO_SUCCESS = "ADD_TODO_SUCCESS";
export const ADD_TODO_FAIL = "ADD_TODO_FAIL";

export const ADD_EMOTE = "ADD_EMOTE";
export const SEND_EMOTE_SUCCESS = "SEND_EMOTE_SUCCESS";
export const SEND_EMOTE_FAIL = "SEND_EMOTE_FAIL";
export const SEND_EMOTE_START = "SEND_EMOTE_START";

export const CLEAR_ALL_EMOTES = "CLEAR_ALL_EMOTES";

export const SET_STREAMER_PUBLISHED = "SET_STREAMER_PUBLISHED";

export const DELETE_TODO_START = "DELETE_TODO_START";
export const DELETE_TODO_SUCCESS = "DELETE_TODO_SUCCESS";
export const DELETE_TODO_FAIL = "DELETE_TODO_FAIL";

// Snackbar Actions
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

// UserDataSet Action

export const SET_USER_DATA_SET = "SET_USER_DATA_SET";
export const SET_FILTERED_USER_DATA_SET = "SET_FILTERED_USER_DATA_SET";
export const CLEAR_USER_DATA_SET = "CLEAR_USER_DATA_SET";

// Statistics Action
export const SET_FILTERS = "SET_FILTERS";
export const SET_CURRENT_FILTER_GROUP = "SET_CURRENT_FILTER_GROUP";
export const CLEAR_CURRENT_FILTER_GROUP = "CLEAR_CURRENT_FILTER_GROUP";
export const CLEAR_CURRENT_FILTER_GROUP_FILTERED_DATA =
   "CLEAR_CURRENT_FILTER_GROUP_FILTERED_DATA";
export const LOADING_FILTER_GROUP_START = "LOADING_FILTER_GROUP_START";
export const LOADING_FILTER_GROUP_END = "LOADING_FILTER_GROUP_END";
export const SET_FILTER_LABEL = "SET_FILTER_LABEL";
export const SET_FILTER_LABEL_ERROR = "SET_FILTER_LABEL_ERROR";
export const CLEAR_FILTER_LABEL_ERROR = "CLEAR_FILTER_LABEL_ERROR";
export const SET_TOTAL_FILTER_GROUP_USERS = "SET_TOTAL_FILTER_GROUP_USERS";
export const SET_FILTERED_FILTER_GROUP_USERS =
   "SET_FILTERED_FILTER_GROUP_USERS";
export const SET_CURRENT_FILTER_GROUP_FILTERED =
   "SET_CURRENT_FILTER_GROUP_FILTERED";
export const SET_CURRENT_FILTER_GROUP_NOT_FILTERED =
   "SET_CURRENT_FILTER_GROUP_NOT_FILTERED";

// Next Livestreams Actions

export const TOGGLE_NEXT_LIVESTREAMS_FILTER = "TOGGLE_NEXT_LIVESTREAMS_FILTER";
export const OPEN_NEXT_LIVESTREAMS_FILTER = "OPEN_NEXT_LIVESTREAMS_FILTER";
export const CLOSE_NEXT_LIVESTREAMS_FILTER = "CLOSE_NEXT_LIVESTREAMS_FILTER";

// Stream Actions

export const OPEN_STREAMER_BREAKOUT_MODAL = "OPEN_STREAMER_BREAKOUT_MODAL";
export const CLOSE_STREAMER_BREAKOUT_MODAL = "CLOSE_STREAMER_BREAKOUT_MODAL";
export const OPEN_VIEWER_BREAKOUT_MODAL = "OPEN_VIEWER_BREAKOUT_MODAL";
export const CLOSE_VIEWER_BREAKOUT_MODAL = "CLOSE_VIEWER_BREAKOUT_MODAL";
export const SET_NUMBER_OF_VIEWERS = "SET_NUMBER_OF_VIEWERS";
export const OPEN_VIEWER_CTA_MODAL = "OPEN_VIEWER_CTA_MODAL";
export const CLOSE_VIEWER_CTA_MODAL = "CLOSE_VIEWER_CTA_MODAL";

export const MUTE_ALL_REMOTE_VIDEOS = "MUTE_ALL_REMOTE_VIDEOS";
export const UNMUTE_ALL_REMOTE_VIDEOS = "UNMUTE_ALL_REMOTE_VIDEOS";
export const SET_VIDEO_IS_MUTED = "SET_VIDEO_IS_MUTED";
export const SET_VIDEO_IS_PAUSED = "SET_VIDEO_IS_PAUSED";
export const TOGGLE_LOCAL_AUDIO = "TOGGLE_LOCAL_AUDIO";
export const TOGGLE_LOCAL_VIDEO = "TOGGLE_LOCAL_VIDEO";

export const CLOSE_LEFT_MENU = "CLOSE_LEFT_MENU";
export const OPEN_LEFT_MENU = "OPEN_LEFT_MENU";
export const TOGGLE_LEFT_MENU = "TOGGLE_LEFT_MENU";

export const SET_FOCUS_MODE = "SET_FOCUS_MODE";

export const SET_AGORA_RTC_CONNECTION_STATE = "SET_AGORA_RTC_CONNECTION_STATE";
export const SET_AGORA_RTC_ERROR = "SET_AGORA_RTC_ERROR";
export const SET_DEVICE_ERROR = "SET_DEVICE_ERROR";
export const SET_SCREEN_SHARE_DENIED_ERROR = "SET_SCREEN_SHARE_DENIED_ERROR";
export const CLEAR_AGORA_RTC_ERROR = "CLEAR_AGORA_RTC_ERROR";
export const SET_AGORA_PRIMARY_CLIENT_JOINED =
   "SET_AGORA_PRIMARY_CLIENT_JOINED";
export const SET_SESSION_IS_USING_CLOUD_PROXY =
   "SET_SESSION_IS_USING_CLOUD_PROXY";
export const SET_SPY_MODE = "SET_SPY_MODE";

export const UNMUTE_MUTED_REMOTE_VIDEOS_ON_FAIL =
   "UNMUTE_MUTED_REMOTE_VIDEOS_ON_FAIL";
export const UNPAUSE_PAUSED_REMOTE_VIDEOS_ON_FAIL =
   "UNPAUSE_PAUSED_REMOTE_VIDEOS_ON_FAIL";

// General Layout Actions
export const OPEN_NAV_DRAWER = "OPEN_NAV_DRAWER";
export const CLOSE_NAV_DRAWER = "CLOSE_NAV_DRAWER";
export const TOGGLE_NAV_DRAWER = "TOGGLE_NAV_DRAWER";

// Group Dashboard Actions

export const SET_STREAMS_FROM_TIMEFRAME_AND_FUTURE =
   "SET_STREAMS_FROM_TIMEFRAME_AND_FUTURE";
export const CLEAR_STREAMS_FROM_TIMEFRAME_AND_FUTURE =
   "CLEAR_STREAMS_FROM_TIMEFRAME_AND_FUTURE";
export const CLEAR_HIDDEN_STREAM_IDS = "CLEAR_HIDDEN_STREAM_IDS";
export const SET_VISIBLE_STREAM_IDS = "SET_VISIBLE_STREAM_IDS";

// stream Admin Actions

export const SET_RECORDING_REQUEST_STARTED = "SET_RECORDING_REQUEST_STARTED";
export const SET_RECORDING_REQUEST_STOPPED = "SET_RECORDING_REQUEST_STOPPED";
